.about__container {
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 15%;
}

.about__me {
  margin-top: 2rem;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0deg);
  transition: var(--transition);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  text-align: center;
  padding: 2rem;
}
.about__card:hover {
  background: transparent;
  border: 1px solid transparent;
  transition: var(--transition);
  cursor: default;
}
.about__icon {
  color: var(--color-primary);
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
}
.about__icon:hover {
  color: var(--color-white);
  transform: rotateZ(360deg);
  transition: all ease 1000ms;
}
.about__card h5 {
  font-size: 0.98rem;
}
.about__card small {
  font-size: 0.74rem;
  margin-bottom: 0.9rem;
  padding-bottom: 0.5rem;
  color: var(--color-light);
}
.about__content p {
  margin: 2rem 0rem 2.6rem;
  color: var(--color-light);
}

/* ================Media Queries (Medium Sized Devices)====== */

@media screen and (max-width: 1024px) {
  /* .about__container {
        display: block;
        grid-template-columns: 30% 60%;
        gap: 15%;
      } */
  .about__container {
    display: block;
    grid-template-columns: 2fr;
    gap: 0;
  }
  .about__me {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    width: 55%;
    aspect-ratio: 1/1;
    border-radius: 5rem;
    background: linear-gradient(
      45deg,
      transparent,
      var(--color-primary),
      transparent
    );
    display: block;
    place-items: center;
  }
  .about__me-image {
    margin-top: 2rem;
    border-radius: 5rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
    margin-bottom: 6rem;
  }
  .about__me-image:hover {
    transform: rotate(0deg);
    transition: var(--transition);
  }
  .about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .about__content p {
    margin: 2rem 0 1.5rem;
  }
  .about__content {
    text-align: center;
  }
}

/* ================Media Queries (Small Sized Devices)====== */

@media screen and (max-width: 600px) {
  .about__container {
    display: block;
    grid-template-columns: 30% 60%;
    gap: 0;
  }
  .about__cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    width: 90%;
    margin: auto;
    padding-bottom: 3rem;
  }
  .about__me {
    width: 80%;
    margin: 0 auto 3rem;
  }
  .about__content p {
    width: auto;
    padding: 0 0.25rem 0;
  }
  .about__content {
    text-align: center;
  }
  .about__card h5 {
    padding-bottom: 0.5rem;
  }
}
