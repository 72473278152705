.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 3rem;
  border-radius: 2.3rem;
  border: 1.7px solid transparent;
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
  /* border-color: transparent; */
  transition: var(--transition);
}
.experience__container > div h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
}
.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  align-items: center;
}
.experience__details {
  display: flex;
  gap: 1rem;
}
.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* ================Media Queries (Medium Sized Devices)====== */

@media screen and (max-width: 1070px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience__content {
    padding: 1rem;
  }
  /* .experience__details {
    display: flex;
    gap: 1rem;
  }
  .experience__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
  } */
}

/* ================Media Queries (Small Sized Devices)====== */

@media screen and (max-width: 600px) {
  .experience__container {
    /* grid-template-columns: 1fr;
    width: 100%; */
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 1.3rem 1rem;
  }
  .experience__details {
    gap: 0.5rem;
  }
  .experience__content {
    padding: 0.2rem;
  }
}
